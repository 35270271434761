import React, { useEffect, useState } from 'react';
import { FiCalendar, FiSearch, FiPhone } from "react-icons/fi";
import { IoSpeedometerOutline } from "react-icons/io5";
import { PiGasCan } from "react-icons/pi";
import { TbManualGearbox } from "react-icons/tb";
import { CiGlobe } from "react-icons/ci";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";
import loadingAnimation from "../assests/car-loading.json";
import { baseUrl, baseUrlForStorage } from "../config";
import { Typography, Box, Slider, Button, IconButton } from "@mui/material";
import Modal from "react-modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import gsap from "gsap";
import "../Styles/Search.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

const Search = () => {
  const [data, setData] = useState([]);
  const [dataTwo, setDataTwo] = useState([]);
  const [dataThree, setDataThree] = useState([]);
  const [activePopupId, setActivePopupId] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [value1, setValue1] = useState([0, 499]);
  const [value2, setValue2] = useState([2000, 2024]);
  const [value3, setValue3] = useState([0, 200]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedCarTitle, setSelectedCarTitle] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [keyword, setKeyword] = useState("");
  const [selectedManufacturer, setSelectedManufacturer] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [sortOption, setSortOption] = useState("");
  const [showContactOptionsId, setShowContactOptionsId] = useState(null);

  const openContactOptions = (carId) => {
    setShowContactOptionsId(carId);
  };

  const fetchSortedCars = async (sortOption) => {
    setLoading(true);
    try {
      const response = await fetch(`${baseUrl}cars/sort?sort=${sortOption}`);
      if (!response.ok) throw new Error("Failed to fetch");
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error("Failed to fetch sorted cars:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleBrandClick = async (manufacturerId) => {
    setLoading(true);
    try {
      const response = await fetch(`${baseUrl}car-listings/by-manufacturer/${manufacturerId}`);
      if (!response.ok) throw new Error("Network response was not ok");
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error("Failed to fetch data: ", error);
    } finally {
      setLoading(false);
      toast.success("Data loaded successfully!", { position: "bottom-center" });
    }
  };

  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };

  const handleSearchWithFilter = async () => {
    setLoading(true);
    let queryParams = new URLSearchParams();

    if (selectedManufacturer) {
      queryParams.append("manufacturer", selectedManufacturer);
    }

    if (selectedModel) {
      queryParams.append("model", selectedModel);
    }

    if (value1) {
      queryParams.append("priceRange", `${value1[0] * 1000},${value1[1] * 1000}`);
    }

    if (value2) {
      queryParams.append("yearRange", `${value2[0]},${value2[1]}`);
    }

    if (value3) {
      queryParams.append("kmRange", `${value3[0] * 1000},${value3[1] * 1000}`);
    }

    try {
      const response = await fetch(`${baseUrl}cars/searchwithfilter?${queryParams}`);
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoading(false);
      toast.success("Data loaded successfully!", { position: "bottom-center" });
    }
  };

  const handleSearch = async () => {
    setLoading(true);
    const searchUrl = `${baseUrl}cars/search?${selectedFilter}=${keyword}`;

    try {
      const response = await fetch(searchUrl);
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error("Search request failed", error);
    } finally {
      setLoading(false);
      toast.success("Data loaded successfully!", { position: "bottom-center" });
    }
  };

  const handleSearchClick = () => {
    handleSearch();
  };

  useEffect(() => {
    if (sortOption) {
      fetchSortedCars(sortOption);
    }
  }, [sortOption]);

  useEffect(() => {
    let searchInp = document.querySelector(".search-inp-container");

    gsap.fromTo(
      searchInp,
      { x: "-100%" },
      { duration: 1, ease: "bounce.out", x: 0 }
    );
    const closePopupOnScroll = () => setActivePopupId(null);

    window.addEventListener("scroll", closePopupOnScroll);

    return () => window.removeEventListener("scroll", closePopupOnScroll);
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "50%",
      maxWidth: "600px",
      border: "1px solid #ccc",
      background: "#fff",
      overflow: "auto",
      WebkitOverflowScrolling: "touch",
      borderRadius: "4px",
      outline: "none",
      padding: "20px",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      backdropFilter: "blur(5px)",
      animation: "fadeIn 0.5s ease-in-out",
    },
  };

  const handleChange1 = (event, newValue) => {
    setValue1(newValue);
  };

  const handleChange2 = (event, newValue) => {
    setValue2(newValue);
  };

  const handleChange3 = (event, newValue) => {
    setValue3(newValue);
  };

  useEffect(() => {
    setLoading(true);

    Promise.all([
      fetch(`${baseUrl}manufacturers`).then((response) => response.json()),
      fetch(`${baseUrl}body-types`).then((response) => response.json()),
      fetch(`${baseUrl}car-listings`).then((response) => response.json()),
    ])
      .then(([manufacturersData, bodyTypesData, carListingsData]) => {
        setDataTwo(manufacturersData);
        setData(bodyTypesData);
        setData(carListingsData);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleManufacturerChange = (event) => {
    const selectedId = event.target.value;
    setSelectedManufacturer(selectedId);
    setSelectedModel(""); // Reset model when manufacturer changes
  };

  useEffect(() => {
    if (selectedManufacturer) {
      fetch(`${baseUrl}car-models/manufacturer/${selectedManufacturer}`)
        .then((response) => response.json())
        .then((models) => setDataThree(models))
        .catch((error) => console.error(error));
    } else {
      setDataThree([]);
    }
  }, [selectedManufacturer]);

  const resetFilters = () => {
    setSelectedManufacturer("");
    setSelectedModel("");
    setValue1([0, 100000]);
    setValue2([2000, 2024]);
    setValue3([0, 200]);

    fetchInitialData();
  };

  const fetchInitialData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${baseUrl}car-listings`);
      if (!response.ok) throw new Error("Failed to fetch initial data");
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error("Error fetching initial data:", error);
    } finally {
      setLoading(false);
      toast.success("Filter reset successfully!", { position: "bottom-center" });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setShowContactOptionsId(null);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <div className="search-container pt-5">
        <div className="container-xl">
          <div className="search-inp-container mt-5"></div>

          <div className="search-and-filter-container">
            <div className="custom-search-container">
              <select
                className="filter-dropdown"
                value={selectedFilter}
                onChange={(e) => setSelectedFilter(e.target.value)}
              >
                <option value="all">All</option>
                <option value="manufacturer">By Make</option>
                <option value="model">By Model</option>
                <option value="year">By Year</option>
              </select>
              <input
                type="text"
                className="custom-search-input"
                placeholder="Search by Make, Model, Year"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
              />
              <button onClick={handleSearchClick} className="custom-search-btn">
                <FiSearch />
              </button>
            </div>

            <select
              onChange={handleSortChange}
              className="additional-filter-dropdown"
            >
              <option value="">Sort By</option>
              <option value="price_low_high">Price: Low to High</option>
              <option value="price_high_low">Price: High to Low</option>
              <option value="newest_to_oldest">Newest to Oldest</option>
              <option value="oldest_to_newest">Oldest to Newest</option>
            </select>
          </div>

          <div className="filter-resulte-container mt-5 d-flex">
            <div className="filter-container">
              <h3 className="fs-5 fw-bold text-center pb-3">What are you looking for?</h3>
              <div className="custom-select-container">
                <select
                  value={selectedManufacturer}
                  onChange={handleManufacturerChange}
                  className="custom-select-style"
                  name="cars"
                  id="cars"
                  style={{ fontSize: "0.9rem" }}
                >
                  <option value="" disabled>
                    Choose Manufacturer
                  </option>
                  {dataTwo &&
                    dataTwo.map((ele) => (
                      <option key={ele.id} value={ele.id}>
                        {ele.name}
                      </option>
                    ))}
                </select>
              </div>

              <div
                className="select-container"
                style={{
                  transition: 'opacity 0.3s ease-in-out',
                  opacity: dataThree && dataThree.length > 0 ? 1 : 0,
                }}
              >
                {dataThree && dataThree.length > 0 ? (
                  <select
                    value={selectedModel}
                    onChange={(e) => setSelectedModel(e.target.value)}
                    className="custom-select-style py-2 px-3 mt-4"
                    name="cars"
                    id="cars"
                    style={{ fontSize: "0.9rem" }}
                  >
                    <option value="" disabled>
                      Choose Model
                    </option>
                    {dataThree.map((ele) => (
                      <option key={ele.id} value={ele.id}>
                        {ele.name}
                      </option>
                    ))}
                  </select>
                ) : (
                  <p style={{ textAlign: 'center', color: 'gray' }}></p>
                )}
              </div>

              <div
                className="slider-filter mt-4 d-flex flex-column align-items-center justify-content-center"
                style={{
                  padding: "20px",
                  backgroundColor: "#f0f0f0",
                  borderRadius: "10px",
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{
                    marginTop: "10px",
                    fontFamily: "montserrat, Arial, sans-serif",
                    fontWeight: "bold",
                  }}
                >
                  Price
                </Typography>
                <Box sx={{ width: "90%" }}>
                  <Slider
                    getAriaLabel={() => "Price range"}
                    value={value1}
                    onChange={handleChange1}
                    valueLabelDisplay="auto"
                    min={10}
                    max={499}
                    step={1}
                    sx={{
                      "& .MuiSlider-thumb": {
                        height: 24,
                        width: 24,
                        backgroundColor: "#fff",
                        "&:hover": {
                          boxShadow: "0 0 0 8px rgba(255, 255, 255, 0.24)",
                        },
                        "&.Mui-active": {
                          boxShadow: "0 0 0 14px rgba(255, 255, 255, 0.24)",
                        },
                      },
                      "& .MuiSlider-track": {
                        height: 8,
                        borderRadius: 4,
                        backgroundColor: "#000",
                      },
                      "& .MuiSlider-rail": {
                        height: 8,
                        borderRadius: 4,
                        backgroundColor: "#bcbcbc",
                      },
                      "& .MuiSlider-valueLabel": {
                        lineHeight: "1.4em",
                        fontSize: "0.75em",
                        background: "unset",
                        padding: "2px",
                        width: "auto",
                        color: "#000",
                        "&:before": { display: "none" },
                        "& *": {
                          background: "transparent",
                          color: "#000",
                        },
                      },
                    }}
                  />
                </Box>
                <Typography
                  variant="body2"
                  gutterBottom
                  style={{
                    marginTop: "0px",
                    fontSize: "0.7rem",
                    fontFamily: "montserrat, Arial, sans-serif",
                  }}
                >
                  Price Range: AED {value1[0] * 1000} - AED {value1[1] * 1000}
                </Typography>
                <hr className="divider" />
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{
                    marginTop: "0px",
                    fontFamily: "montserrat, Arial, sans-serif",
                    fontWeight: "bold",
                  }}
                >
                  Year
                </Typography>
                <Box sx={{ width: "90%" }}>
                  <Slider
                    getAriaLabel={() => "Year range"}
                    value={value2}
                    onChange={handleChange2}
                    valueLabelDisplay="auto"
                    min={2000}
                    max={2024}
                    sx={{
                      "& .MuiSlider-thumb": {
                        height: 24,
                        width: 24,
                        backgroundColor: "#fff",
                        "&:hover": {
                          boxShadow: "0 0 0 8px rgba(255, 255, 255, 0.24)",
                        },
                        "&.Mui-active": {
                          boxShadow: "0 0 0 14px rgba(255, 255, 255, 0.24)",
                        },
                      },
                      "& .MuiSlider-track": {
                        height: 8,
                        borderRadius: 4,
                        backgroundColor: "#000",
                      },
                      "& .MuiSlider-rail": {
                        height: 8,
                        borderRadius: 4,
                        backgroundColor: "#bcbcbc",
                      },
                      "& .MuiSlider-valueLabel": {
                        lineHeight: "1.4em",
                        fontSize: "0.75em",
                        background: "unset",
                        padding: "2px",
                        width: "auto",
                        color: "#000",
                        "&:before": { display: "none" },
                        "& *": {
                          background: "transparent",
                          color: "#000",
                        },
                      },
                    }}
                  />
                </Box>
                <Typography
                  variant="body2"
                  gutterBottom
                  style={{
                    marginTop: "0px",
                    fontSize: "0.8rem",
                    fontFamily: "montserrat, Arial, sans-serif",
                  }}
                >
                  Year Range: {value2[0]} - {value2[1]}
                </Typography>
                <hr className="divider" />
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{
                    marginTop: "0px",
                    fontFamily: "montserrat, Arial, sans-serif",
                    fontWeight: "bold",
                  }}
                >
                  Mileage Range
                </Typography>

                <Box sx={{ width: "90%" }}>
                  <Slider
                    getAriaLabel={() => "Km range"}
                    value={value3}
                    onChange={handleChange3}
                    valueLabelDisplay="auto"
                    min={0}
                    max={200}
                    sx={{
                      "& .MuiSlider-thumb": {
                        height: 24,
                        width: 24,
                        backgroundColor: "#fff",
                        "&:hover": {
                          boxShadow: "0 0 0 8px rgba(255, 255, 255, 0.24)",
                        },
                        "&.Mui-active": {
                          boxShadow: "0 0 0 14px rgba(255, 255, 255, 0.24)",
                        },
                      },
                      "& .MuiSlider-track": {
                        height: 8,
                        borderRadius: 4,
                        backgroundColor: "#000",
                      },
                      "& .MuiSlider-rail": {
                        height: 8,
                        borderRadius: 4,
                        backgroundColor: "#bcbcbc",
                      },
                      "& .MuiSlider-valueLabel": {
                        lineHeight: "1.4em",
                        fontSize: "0.75em",
                        background: "unset",
                        padding: "2px",
                        width: "auto",
                        color: "#000",
                        "&:before": { display: "none" },
                        "& *": {
                          background: "transparent",
                          color: "#000",
                        },
                      },
                    }}
                  />
                </Box>
                <Typography
                  variant="body2"
                  gutterBottom
                  style={{
                    marginTop: "0px",
                    fontSize: "0.8rem",
                    fontFamily: "montserrat, Arial, sans-serif",
                  }}
                >
                  Km Range: {value3[0] * 1000} - {value3[1] * 1000} km
                </Typography>
              </div>

              <button
                className="search-btn py-2 px-3 d-block mt-4"
                onClick={handleSearchWithFilter}
              >
                Search
              </button>
              <button
                className="reset-filter-btn d-block mt-2"
                onClick={resetFilters}
                style={{
                  textDecoration: "underline",
                  background: "none",
                  border: "none",
                  color: "black",
                  cursor: "pointer",
                }}
              >
                Reset Filters
              </button>
              <div className="cars-brand-C">
                <h3 className="fs-5 fw-bold pb-2 mt-4">Car Brands</h3>
                <div className="cars-brand-container">
                  {dataTwo &&
                    dataTwo.map((ele) => (
                      <div
                        className="d-flex align-items-center gap-3 ps-2 mb-2"
                        key={ele.id}
                        onClick={() => handleBrandClick(ele.id)}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="car-brand-img d-flex align-items-center justify-content-center">
                          <img
                            src={`${baseUrlForStorage}/${ele.image}`}
                            alt={ele.name}
                          />
                        </div>

                        <h3 className="car-brand-name m-0 fw-bold">
                          {ele.name}
                        </h3>
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <div className="result-container">
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Lottie options={defaultOptions} height={150} width={150} />
                </div>
              ) : data && data.length > 0 ? (
                data.map((car) => (
                  <div className="new-arrival-feature" key={car.id}>
                    <div className="new-arrival-feature-img">
                      <Link to={`/car-details/${car.id}`}>
                        <img
                          src={`${baseUrlForStorage}/${car.image}`}
                          alt={car.title}
                        />
                      </Link>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <Link to={`/car-details/${car.id}`}>
                        <p className="fs-8 fw-bold ps-2 pt-2">
                          {car.title.length > 20 ? car.title.substring(0, 15) + '...' : car.title}
                        </p>
                      </Link>
                      <h3 className="fs-6 fw-bold pe-2 pt-2 price">
                        <span>AED</span> {Math.floor(car.price)}
                      </h3>
                    </div>
                    <div className="new-arrival-infos d-flex align-items-center justify-content-between mx-2">
                      <div className="info d-flex align-items-center justify-content-center gap-1">
                        <FiCalendar />
                        <p className="m-0">{car.year}</p>
                      </div>
                      <div className="info d-flex align-items-center justify-content-center gap-1">
                        <CiGlobe />
                        <p className="m-0">{car.regional_spec}</p>
                      </div>
                      <div className="info d-flex align-items-center justify-content-center gap-1">
                        <PiGasCan />
                        <p className="m-0">{car.fuel_type}</p>
                      </div>
                      <div className="info d-flex align-items-center justify-content-center gap-1">
                        <IoSpeedometerOutline />
                        <p className="m-0">{car.mileage}</p>
                      </div>
                    </div>
                    <div className="new-arrival-btns d-flex align-items-center justify-content-center gap-2 mx-2 my-2">
                      <div style={{ position: "relative", flex: 4 }}>
                        <button
                          className="btn-interest py-2"
                          onClick={() => openContactOptions(car.id)}
                          style={{
                            height: "100%",
                            width: "100%",
                            border: "none",
                          
                            backgroundColor: "#000",
                            color: "#fff",
                            fontSize: "14px",
                            cursor: "pointer",
                            transition: "background-color 0.3s ease",
                            display: "flex",
                            
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <span> I’m Interested</span>
                        </button>
                        {showContactOptionsId === car.id && (
                          <div className="contact-options-container">
                            <div className="contact-info">
                              <h3 className="contact-title">{car.title}</h3>
                              <h3 className="contact-title">{car.car_model["name"]}</h3>
                              <p className="contact-name">{car.owner["name"]}</p>
                              <p className="operational-hours">Seller</p>
                            </div>
                            <a
                              href={`tel:${car.owner["phone_number"]}`}
                              className="contact-option phone-call-button"
                            >
                              <span className="button-text-call">Call Now</span>
                            </a>
                            <a
  href={`https://wa.me/${car.owner["phone_number"]
    .replace(/^00/, "+")}?text=Hello, I'm interested in your car. Car Title: ${
    car.title
  } Year: ${car.year} Price: ${car.price}`}
  className="contact-option whatsapp-button"
  target="_blank"
  rel="noopener noreferrer"
>
                              <span className="button-text-call">Message on WhatsApp</span>
                            </a>
                          </div>
                        )}
                      </div>
                      <Link
                        className="link-view-button"
                        to={`/car-details/${car.id}`}
                        style={{
                          flex: 1,
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#fff",
                          color: "#fff",
                          textDecoration: "none",
                          fontSize: "14px",
                          transition: "background-color 0.3s ease",
                        }}
                        
                      >
                        View
                      </Link>
                    </div>
                  </div>
                ))
              ) : (
                <div style={{ textAlign: "center", width: "100%" }}>
                  <p>No results found</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Search;
