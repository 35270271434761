import google from "../assests/google.png";
import { MdOutlineStarPurple500 } from "react-icons/md";
import "../Styles/Reviews.css";
import { Link } from "react-router-dom";

const Reviews = () => {
  return (
    <>
      <div className="container-xl">
        {" "}
        <div className="reviews-img text-center mt-4">
          <img src={google} alt="" />
        </div>
        <div className="reviews d-flex align-items-center justify-content-center gap-2">
          <p className="m-0 fw-bold">Reviews </p>
          <div className="stars d-flex align-items-center justify-content-center gap-1 fs-5">
            <MdOutlineStarPurple500 className="star" />
            <MdOutlineStarPurple500 className="star" />
            <MdOutlineStarPurple500 className="star" />
            <MdOutlineStarPurple500 className="star" />
            <MdOutlineStarPurple500 className="star" />
          </div>
        </div>
        <span className="text-center d-block check-reviews mt-3">
          {" "}
          <Link className="mt-5 fw-bold ">View All Reviews</Link>
        </span>
      </div>
    </>
  );
};

export default Reviews;
