import { Link } from "react-router-dom";
import "../Styles/InstagramPost.css";
const InstagramPost = () => {
  return (
    <>
      <div className="instagram-container py-5">
        <div className="container-xl">
          <h3 className="text-center fs-1" data-aos="fade-down-right">
            Instagram
          </h3>
          <div className="instagram-posts flex-wrap d-flex align-items-center  px-5 mt-4">
            <Link
              className="d-block post"
              data-aos="zoom-out"
              data-aos-delay="100"
            >
              <img
                src="https://images.pexels.com/photos/112460/pexels-photo-112460.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
              />{" "}
            </Link>
            <Link
              className="d-block post"
              data-aos="zoom-out"
              data-aos-delay="200"
            >
              <img
                src="https://images.pexels.com/photos/112460/pexels-photo-112460.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
              />{" "}
            </Link>
            <Link
              className="d-block post"
              data-aos="zoom-out"
              data-aos-delay="300"
            >
              <img
                src="https://images.pexels.com/photos/112460/pexels-photo-112460.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
              />{" "}
            </Link>
          </div>
        </div>{" "}
        <div className="instagram-bg d-flex align-items-center justify-content-center">
          <h3 className="fs-1 " data-aos="fade-down" data-aos-delay="100">
            Instagram -
          </h3>
          <h3 className="fs-1 " data-aos="fade-down" data-aos-delay="200">
            Instagram -
          </h3>
          <h3 className="fs-1 " data-aos="fade-down" data-aos-delay="300">
            Instagram -
          </h3>
          <h3 className="fs-1 " data-aos="fade-down" data-aos-delay="400">
            Instagram -
          </h3>
          <h3 className="fs-1 " data-aos="fade-down" data-aos-delay="500">
            Instagram -
          </h3>
        </div>
      </div>
    </>
  );
};

export default InstagramPost;
