import { useEffect, useState } from "react";
import Header from "../Comp/Header";
import "../Styles/SellYourCar.css";
import Footer from "../Comp/Footer";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";
import { baseUrl } from "../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SellYourCar = () => {
  const [data, setData] = useState([]);
  const [dataThree, setDataThree] = useState([]);

  const [selectedOption, setSelectedOption] = useState("");

  const { ref, inView } = useInView({
    triggerOnce: true, // This ensures the animation only plays once
    threshold: 0.5, // Adjust as needed to control when the animation triggers
  });

  const fadeIn = useSpring({
    to: {
      opacity: inView ? 1 : 0,
      transform: inView ? "translateY(0)" : "translateY(50px)",
    },
    from: {
      opacity: 0,
      transform: "translateY(50px)",
    },
    config: { mass: 1, tension: 210, friction: 20 },
    delay: 300, // Adjust based on your preference
  });

  const [form, setForm] = useState({
    manufacturer: "",
    model: "",
    model_year: "",
    chassis_no: "",
    kilometers: "",
    engine_size: "",
    vehicle_options: "",
    expected_price: "",
    description: "",
    full_name: "",
    mobile_number: "",
    email_address: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };
  const validateForm = () => {
    let formIsValid = true;
    let newErrors = {};

    if (!form.first_name) {
      formIsValid = false;
      newErrors.first_name = "First name is required";
    }
    // Add more validation as necessary

    return formIsValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the form from submitting in the traditional way

    try {
      const response = await fetch(`${baseUrl}car-sales`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(form),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error(errorData);
        // Use toast to display error message
        toast.error(
          "Submission failed: " +
            (errorData.message || "Please check the form.")
        );
        return;
      }

      // On successful submission
      const result = await response.json();
      console.log(result);
      // Use toast to display success message
      toast.success("Form submitted successfully!");

      // Reset the form
      setForm({
        manufacturer: "",
        model: "",
        year: "",
        chassis_no: "",
        kilometers: "",
        engine_size: "",
        vehicle_options: "",
        expected_price: "",
        description: "",
        full_name: "",
        mobile_number: "",
        email_address: "",
      });
    } catch (error) {
      console.error(error);
      // Use toast to display error message
      toast.error("An unexpected error occurred.");
    }
  };

  useEffect(() => {
    fetch(`${baseUrl}manufacturers`)
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (selectedOption) {
      fetch(`${baseUrl}car-models/manufacturer/${selectedOption}`)
        .then((response) => response.json())
        .then((data) => setDataThree(data))
        .catch((error) => console.error(error));
    } else {
      setDataThree([]); // Reset models if no manufacturer is selected
    }
  }, [selectedOption]);

  const combinedChangeHandler = (event) => {
    handleChange(event); // First handler
    handleManufacturerChange(event); // Second handler
  };

  const combinedModelChangeHandler = (event) => {
    handleChange(event); // Handles updates to the form state
    handleModelChange(event); // Handles model-specific logic
  };
  const handleManufacturerChange = (event) => {
    const selectedId = event.target.value; // Assuming the value is the manufacturer's ID
    setSelectedOption(selectedId); // Update selected manufacturer ID
    setForm({ ...form, manufacturer: selectedId, model: "" }); // Reset model selection on manufacturer change
  };

  // Model Select Change Handler
  const handleModelChange = (event) => {
    const selectedModel = event.target.value;
    setForm({ ...form, model: selectedModel });
  };

  return (
    <>
      <div className="Contact-us-container">
        <div className="sell-your-car">
          <img
            src="https://images.unsplash.com/photo-1629820408206-e9fc918abf63?q=80&w=1386&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt=""
            className="sell-your-car-image"
          />
          <h1 className="display-2 about-us-title text-light fw-bold ">
            Sell Your Car
          </h1>
          <div className="head-container">
            <div className="container-xxl pt-3">
              <Header />
            </div>
          </div>
        </div>

        <div className="sell-your-car-inputs-container py-5">
          <div className="container-xl">
            <form onSubmit={handleSubmit}>
              <div className="inputs-container py-5">
                <div className="sell-car-inputs">
                  <p className="fw-bold">Manufacturer *</p>
                  <input
  className="py-2 px-2"
  type="text"
  name="manufacturer"
  value={form.manufacturer}
  onChange={combinedChangeHandler}
  id="" // Consider providing a meaningful ID if necessary
  placeholder="Please enter Manufacturer"
  required
/>
                </div>
                <div className="sell-car-inputs">
                  <p className="fw-bold">Model *</p>
                  <input
  type="text"
  value={form.model}
  onChange={combinedModelChangeHandler}
  className="py-2 px-2"
  name="model"
  id="" // Consider providing a meaningful ID if necessary
  placeholder="Please enter Model or Year"
  required
/>

                </div>{" "}
                <div className="sell-car-inputs">
                  <p className="fw-bold">Model Year *</p>
                  <input
  type="number"
  name="model_year"
  value={form.model_year}
  onChange={handleChange}
  className="py-2 px-2"
  placeholder="Enter Model Year"
  required
  min="1990"  // Example: Setting a minimum year
  max="2024"  // Example: Setting a maximum year for future-proofing
/>

                </div>{" "}
                <div className="sell-car-inputs">
                  <p className="fw-bold">Chassis No*</p>
                  <input
                    placeholder="ex : A3"
                    className="py-2 px-2"
                    name="chassis_no"
                    value={form.chassis_no}
                    id=""
                    onChange={handleChange}
                    required
                  />
                </div>{" "}
                <div className="sell-car-inputs">
                  <p className="fw-bold">Kilometers *</p>
                  <input
                    name="kilometers"
                    placeholder="ex : 150000"
                    className="py-2 px-2"
                    type="number"
                    id=""
                    value={form.kilometers}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="sell-car-inputs">
                  <p className="fw-bold">Engine Size *</p>
                  <input
                    placeholder="ex: 1.8"
                    className="py-2 px-2"
                    type="text"
                    id=""
                    name="engine_size"
                    value={form.engine_size}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="sell-car-inputs">
                  <p className="fw-bold">Vehicle Options *</p>
                  <input
                    placeholder="ex: S Line"
                    className="py-2 px-2"
                    type="text"
                    id=""
                    name="vehicle_options"
                    value={form.vehicle_options}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="sell-car-inputs">
                  <p className="fw-bold">Expected Price *</p>
                  <input
                    placeholder="ex: AED 50.000"
                    className="py-2 px-2"
                    type="number"
                    id=""
                    name="expected_price"
                    value={form.expected_price}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="sell-car-inputs">
                  <p className="fw-bold">Description *</p>
                  <textarea
                    placeholder="ex : Full Service History"
                    className="py-1 px-2"
                    type="text"
                    name="description"
                    value={form.description}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="sell-car-inputs">
                  <p className="fw-bold">Full Name *</p>
                  <input
                    placeholder="John Smith"
                    className="py-2 px-2"
                    type="text"
                    name="full_name"
                    value={form.full_name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="sell-car-inputs">
                  <p className="fw-bold">Mobile Number *</p>
                  <input
                    placeholder="0555 4444888"
                    className="py-2 px-2"
                    type="number"
                    name="mobile_number"
                    value={form.mobile_number}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="sell-car-inputs">
                  <p className="fw-bold">Email Address *</p>
                  <input
                    placeholder="Email"
                    className="py-2 px-2"
                    type="text"
                    name="email_address"
                    value={form.email_address}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <button type="submit" className="py-2 px-2 fw-light mb-4 sub-btn">
                SUBMIT
              </button>
            </form>
            <br />
          </div>
        </div>
        <animated.div ref={ref} style={fadeIn} className="container-xl">
  <h1 className="fs-3 mt-3">SELL YOUR CAR</h1>
  <p>Ready to move on from your current vehicle? Our platform offers a straightforward and secure way to sell your car, ensuring a seamless and rewarding experience from start to finish.</p>
  <p>
    With our expert valuation, hassle-free inspection, and vast network of buyers, we're here to make sure you get the best deal possible. Say goodbye to endless negotiations and uncertainty, and hello to a smooth sale.
  </p>

  <h1 className="fs-6 mt-3 Process-title fw-light">WHAT'S THE PROCESS</h1>
  <span className="mb-3 d-block fw-bold">1 Free, No-Obligation Valuation</span>
  <p>
    Share details about your car and receive a free, no-obligation valuation based on current market trends and our extensive sales data. Our valuations are designed to give you a fair estimate of what your car is worth.
  </p>
  <span className="mb-3 d-block fw-bold">2 Free Inspection</span>
  <p>
    Book an appointment for a free vehicle inspection with one of our certified experts. We'll examine your car to confirm its condition and offer you a final sale price.
  </p>
  <span className="mb-3 d-block fw-bold">3 Complete The Sale</span>
  <p>
    Once you accept our offer, we'll take care of the paperwork and payment process. You'll receive payment quickly and securely, without any hidden fees or charges.
  </p>
  <h1 className="fs-6 mt-3 Process-title fw-light">KEY BENEFITS</h1>
  <ul className="sell-car-ul p-0">
    <li className="mb-1">
      - Competitive and fair valuations based on comprehensive market analysis.
    </li>
    <li className="mb-1">
      - Hassle-free process, with free inspection and no obligation to sell.
    </li>
    <li className="mb-1">
      - Quick and secure payment, ensuring peace of mind.
    </li>
    <li className="mb-1">
      - Expert advice and support from our team throughout the selling process.
    </li>
    <li className="mb-1">
      - Access to a vast network of potential buyers, maximizing your chances of a successful sale.
    </li>
  </ul>
</animated.div>


        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
        />

        <div className="devider"></div>
        <Footer />
      </div>
    </>
  );
};

export default SellYourCar;
