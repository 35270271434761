import { Link } from "react-router-dom";
import "../Styles/BlogPosts.css";
import { useEffect, useState } from "react";
import { baseUrl } from "../config"; // Make sure you have this base URL correctly defined
import he from 'he';




const BlogPost = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [bodyBlog, setBlogPostsBody] = useState([]);

  useEffect(() => {
    // Fetch blog posts when the component mounts
    const fetchBlogPosts = async () => {
      try {
        const response = await fetch(`${baseUrl}blog-posts`);
        const data = await response.json();
        const decodedDescription = he.decode(data.description);
        setBlogPostsBody(decodedDescription);
        const latestBlogPosts = data.sort((a, b) => new Date(b.date) - new Date(a.date)).slice(0, 3);
        setBlogPosts(latestBlogPosts);
      } catch (error) {
        console.error("Failed to fetch blog posts:", error);
      }
    };

    fetchBlogPosts();
  }, []);





  return (
    <>
      <div className="blog-post-container py-5">
        <div className="container-xl">
          <div className="blog-post">
            <h2 className="display-6 mt-3 " data-aos="fade-right">
              Blogs (Blog posts below)
            </h2>
            <div className="blogs flex-wrap d-flex ">
              {blogPosts.map((post, index) => (
                <div
                  key={index}
                  className="blog mt-3"
                  data-aos="fade-left"
                  data-aos-delay={`${100 * (index + 1)}`}
                >
<div className="blog-container" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} dangerouslySetInnerHTML={{ __html: post.description }} />
                  <Link to={post.source} className="blog-source-btn ms-4 ps-1">Source</Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogPost;
