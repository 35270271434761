import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

import { MdOutlineStarPurple500 } from "react-icons/md";

import "../Styles/ProfilesComments.css";
const ProfilesComments = () => {
  return (
    <>
      <div className="overflow-hidden">
        <div className="profile-container pb-5">
          <Swiper
            slidesPerView={"auto"}
            centeredSlides={true}
            spaceBetween={30}
            className="mySwiper Profile-swiper mt-5 mb-3"
          >
            <SwiperSlide className="profile-slide">
              <div className="profile-infos d-flex align-items-center gap-3 ">
                <div className="profile-logo ">
                  <img
                    src="https://images.pexels.com/photos/428364/pexels-photo-428364.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt=""
                  />
                </div>
                <div className="profile-content">
                  <h3 className="profile-name fw-bold fs-6">Sami B</h3>
                  <div className="profile-reviews">
                    <MdOutlineStarPurple500 className="fs-5" />
                    <MdOutlineStarPurple500 className="fs-5" />
                    <MdOutlineStarPurple500 className="fs-5" />
                    <MdOutlineStarPurple500 className="fs-5" />
                    <MdOutlineStarPurple500 className="fs-5" />
                  </div>
                </div>
              </div>{" "}
              <div className="profile-desc ">
                <p>
                  Lorem ipsum dolor sit amet consectetur. Eget eu dui venenatis
                  pharetra euismod tincidunt a tincidunt. Est venenatis id ut
                  quam curabitur scelerisque sagittis sed viverra. Nullam
                  sagittis adipiscing duis semper ornare mollis iaculis.
                  Ultricies in eget mi aliquet aliquet est.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="profile-slide">
              <div className="profile-infos d-flex align-items-center gap-3 ">
                <div className="profile-logo ">
                  <img
                    src="https://images.pexels.com/photos/428364/pexels-photo-428364.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt=""
                  />
                </div>
                <div className="profile-content">
                  <h3 className="profile-name fw-bold fs-6">Sami B</h3>
                  <div className="profile-reviews">
                    <MdOutlineStarPurple500 className="fs-5" />
                    <MdOutlineStarPurple500 className="fs-5" />
                    <MdOutlineStarPurple500 className="fs-5" />
                    <MdOutlineStarPurple500 className="fs-5" />
                    <MdOutlineStarPurple500 className="fs-5" />
                  </div>
                </div>
              </div>{" "}
              <div className="profile-desc ">
                <p>
                  Lorem ipsum dolor sit amet consectetur. Eget eu dui venenatis
                  pharetra euismod tincidunt a tincidunt. Est venenatis id ut
                  quam curabitur scelerisque sagittis sed viverra. Nullam
                  sagittis adipiscing duis semper ornare mollis iaculis.
                  Ultricies in eget mi aliquet aliquet est.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="profile-slide">
              <div className="profile-infos d-flex align-items-center gap-3 ">
                <div className="profile-logo ">
                  <img
                    src="https://images.pexels.com/photos/428364/pexels-photo-428364.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt=""
                  />
                </div>
                <div className="profile-content">
                  <h3 className="profile-name fw-bold fs-6">Sami B</h3>
                  <div className="profile-reviews">
                    <MdOutlineStarPurple500 className="fs-5" />
                    <MdOutlineStarPurple500 className="fs-5" />
                    <MdOutlineStarPurple500 className="fs-5" />
                    <MdOutlineStarPurple500 className="fs-5" />
                    <MdOutlineStarPurple500 className="fs-5" />
                  </div>
                </div>
              </div>{" "}
              <div className="profile-desc ">
                <p>
                  Lorem ipsum dolor sit amet consectetur. Eget eu dui venenatis
                  pharetra euismod tincidunt a tincidunt. Est venenatis id ut
                  quam curabitur scelerisque sagittis sed viverra. Nullam
                  sagittis adipiscing duis semper ornare mollis iaculis.
                  Ultricies in eget mi aliquet aliquet est.
                </p>
              </div>
            </SwiperSlide>
           
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default ProfilesComments;
