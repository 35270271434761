import { Link } from "react-router-dom";
import "../Styles/BlogDetails.css";
import AOS from "aos";
import { useEffect, useState } from "react";
import { baseUrl } from '../config';

AOS.init({
  once: true,
});
const BlogDetails = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(`${baseUrl}second-sections/1`)
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error(error));
  }, []);

  console.log(data.details);
  return (
    <>
      <div className="blog-details py-5">
        <div className="container-xl">
          <div className="blog-details-container">
            <h1 className="display-6 fw-normal m-0 main-title title" data-aos="fade-up">
              {data.main_title}
            </h1>
            <div className="blog-details-features d-flex align-items-center flex-wrap justify-content-between gap-5  mt-3">
              {data &&
                data.details &&
                data.details.map((ele) => {
                  return (
                    <div
                      key={ele.id}
                      className="feature"
                      data-aos="fade-right"
                      data-aos-delay="100"
                    >
                      <h1 className="display-6 fw-normal">{ele.title} </h1>
                      <p>{ele.body}</p>
                    
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetails;
