import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "../Styles/HomeSlider.css";
import slideImg from "../assests/slideImg.png";
import React from "react";
// import required modules
import { Pagination, Autoplay } from "swiper/modules";
import { useEffect, useState } from "react";
import gsap from "gsap";
import { CircleCursor } from 'react-cursors'
import { baseUrl } from '../config';
import { baseUrlForStorage } from "../config";

const HomeSlider = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(`${baseUrl}first-sections/1`)
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error(error));
  
  
  
      let swipTitle = document.querySelector(".swip-title");

    gsap.fromTo(
      swipTitle,
      { x: 0, opacity: 0 },
      { x: 100, duration: 1.5, ease: "back.inOut", opacity: 1 }
    );
  }, []);

  return (
    <>

      <Swiper
        // autoplay={{ delay: 2000 }}

        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Autoplay]}
        className="mySwiper Swpier-C"
      >
        {data &&
          data.images &&
          data.images.map((ele) => {
            return (
              <SwiperSlide className="swip" key={ele.id}>
                <div className="container-xl">
                  <h1 className="display-4 fw-bold text-wrap swip-title">
                   
                    <img
                      className="swip-img"
                      src={`${baseUrlForStorage}/${ele.image_path}`}
                      alt=""
                    />
                  </h1>
                </div>
              </SwiperSlide>
            );
          })}
      </Swiper>
      
    </>
  );
};

export default HomeSlider;
